<template>
    <div class="custom-scroll overflow-auto" style="height:calc(100vh - 205px)">
        <!-- <imagen-component url="https://d1.com.co/wp-content/uploads/2021/04/recetas-banner.jpg" :height="120" class="mb-5" /> -->
        <div v-for="(seccion,key) in secciones" :key="key">
            <titulos-component v-if="[21,22,23].includes(seccion.tipo)" :data="seccion" class="mb-5" @acciones="acciones" />

            <imagenes-component v-if="[11,12].includes(seccion.tipo)" :data="seccion" :changes="updateImagenes" class="mb-5" @acciones="acciones" />


            <categorias-component v-if="[31,32].includes(seccion.tipo)" class="mb-5" :data="seccion" :changes="updateCategorias" :dos-lineas="seccion.tipo == 31 ? false : true" @acciones="acciones" />


            <productos-component v-if="[41,42].includes(seccion.tipo)" :data="seccion" :dos-lineas="seccion.tipo == 41 ? false : true" :changes="updateProductos" class="mb-5" @acciones="acciones" />

            <!-- <pie-de-pagina /> -->
        </div>
        <!-- Partials -->
        <modal-imagenes ref="modalCrearImagenes" @goEventsImages="goEventsImages" @update="updateElements('imagenes')" @updateParametro="loadSecciones" />
        <modal-eliminar ref="modalEliminarSeccion" titulo="Eliminar sección" mensaje="¿Desea eliminar esta sección?" @eliminar="eliminarSeccion" />
        <modal-anadir-enlace ref="modalAnadirEnlace" @redirectImages="goRedirectImages" />
        <modal-crear-titulo ref="modalCrearTitulo" @update="loadSecciones" />
        <modal-ordenar-secciones ref="modalOrdenarSecciones" @update="loadSecciones" />
        <modal-categorias ref="modalCategorias" @update="updateElements('categoria')" @updateParametro="loadSecciones" />
        <modal-productos ref="modalProductos" @update="updateElements('producto')" @updateParametro="loadSecciones" />

        <redes-sociales />
    </div>
</template>

<script>
import Landing from '~/services/landing/landing_admin'
import Service from '~/services/landing/landing_sections'
import { mapGetters } from 'vuex'

export default {
    components: {
        imagenesComponent: () => import('./components/imagenesComponent'),
        titulosComponent: () => import('./components/titulosComponent'),
        categoriasComponent: () => import('./components/categoriasComponent'),
        //imagenComponent: () => import('./components/imagenComponent'),
        productosComponent: () => import('./components/productosComponent'),
        //pieDePagina: () => import('./components/pieDePagina'),
        redesSociales: () => import('./components/redesSociales.vue'),
        modalImagenes: () => import('./partials/modalImagenes'),
        modalAnadirEnlace: () => import('./partials/modalEnlace'),
        modalCrearTitulo: () => import('./partials/modalCrearTitulo'),
        modalOrdenarSecciones: () => import('./partials/modalOrdenarSecciones'),
        modalCategorias: () => import('./partials/modalCategorias'),
        modalProductos: () => import('./partials/modalProductos')
    },
    data(){
        return {
            list: [],
            urlPage: null,
            secciones:[],
            idSeccion: null,
            updateCategorias: false,
            updateImagenes: false,
            updateProductos: false,
        }
    },
    computed:{
        idLanding(){
            return Number(this.$route.params.idLanding)
        },
        ...mapGetters({
            getListenerUpdater: 'landing/landing/getListenerUpdater',
        }),
    },
    watch:{
        getListenerUpdater(){
            if(this.getListenerUpdater.type === 'nuevaData'){
                console.log("listener escuchooo");
                this.loadSecciones()
            }
        },
    },
    created(){
        this.loadSecciones()
    },
    methods: {
        async loadSecciones(){
            try {
                const {data} = await Landing.loadSecciones(this.idLanding)
                this.secciones = [];
                this.secciones = data.secciones
            } catch (error){
                this.error_catch(error)
            }
        },

        acciones(data){
            if(data.tipo == 'editar'){
                switch(data.seccion){
                case "imagen":
                    this.$refs.modalCrearImagenes.toggle('editar', data.idSeccion);
                    return;
                case "titulo":
                    this.$refs.modalCrearTitulo.toggle('editar', data.idSeccion);
                    return;
                case "categoria":
                    this.$refs.modalCategorias.toggle(data.idSeccion);
                    return;
                case "producto":
                    this.$refs.modalProductos.toggle(data.idSeccion);
                    return;
                }
            }
            if(data.tipo == 'inactivar' || data.tipo == 'activar'){
                this.idSeccion = data.idSeccion;
                this.changeStateSection(data.tipo == 'activar' ? 0: 1);
            }
            if(data.tipo == 'eliminar'){
                this.idSeccion = data.idSeccion;
                this.$refs.modalEliminarSeccion.toggle();
            }
            if(data.tipo == 'ordenar'){
                this.$refs.modalOrdenarSecciones.toggle(this.secciones);
            }
        },

        async eliminarSeccion(){
            try {
                if(!this.idSeccion)return;
                const {data} = await Service.deleteSection(this.idSeccion);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.idSeccion = null;
                this.loadSecciones();
                this.$refs.modalEliminarSeccion.toggle();
            } catch(e){
                this.error_catch(e)
            }
        },

        async changeStateSection(estado){
            try {
                const model = {
                    estado: estado
                };
                const {data} = await Service.changeStateSection(model, this.idSeccion);
                let index = this.secciones.findIndex((item) => parseInt(item.id) === parseInt(this.idSeccion));
                if(index != -1){
                    this.secciones[index].estado = estado;
                    this.idSeccion = null;
                }
                this.notificacion('Mensaje', data.mensaje, data.tipo);
            } catch(e){
                this.error_catch(e)
            }
        },

        goEventsImages(data){
            switch(data.bandera){
            case 'asignar':
                return this.$refs.modalAnadirEnlace.toggle(data.idSection, data.idElementSection, 'asignar');
            case 'editar':
                return this.$refs.modalAnadirEnlace.toggle(data.idSection, data.idElementSection, 'editar');
            case 'update':
                return this.loadSecciones();
            }
        },

        goRedirectImages(idSection){
            this.$refs.modalCrearImagenes.toggle('editar', idSection);
        },

        updateElements(bandera){
            switch(bandera){
            case "categoria":
                this.updateCategorias = !this.updateCategorias;
                break;
            case "imagenes":
                this.updateImagenes = !this.updateImagenes;
                break;
            case "producto":
                this.updateProductos = !this.updateProductos;
                break;
            }
        },
    }
}
</script>

